<template>
  <div :class="$style.recipients">
    <router-link to="recipients/create" :class="$style.create">
      <Icon role="button" name="plus" :class="$style.plus" />
      Создать получателя
    </router-link>
    <el-table :data="recipients" stripe>
      <el-table-column prop="lastName" label="Фамилия"
        ><template slot-scope="scope">{{ scope.row.lastName }}</template>
      </el-table-column>
      <el-table-column prop="firstName" label="Имя"
        ><template slot-scope="scope">{{ scope.row.firstName }}</template>
      </el-table-column>
      <el-table-column prop="middleName" label="Отчество"
        ><template slot-scope="scope">{{ scope.row.middleName }}</template>
      </el-table-column>
      <el-table-column prop="phone" label="Телефон"
        ><template slot-scope="scope">{{ scope.row.phone }}</template>
      </el-table-column>
      <el-table-column prop="email" label="Почта"
        ><template slot-scope="scope">{{ scope.row.email }}</template>
      </el-table-column>
      <el-table-column
        ><template slot-scope="scope">
          <ActionButtons
            :edit-link="`/sellers/dealers/${scope.row.dealerAccountId}/recipients/${scope.row.id}`"
            @delete="remove(scope.row)"
            :class="$style.buttons"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import notifications from '@/mixins/notifications'
import Icon from '@/components/atoms/Icon'

export default {
  components: { ActionButtons, Icon },
  mixins: [notifications],
  data() {
    return {
      recipients: [],
    }
  },
  created() {
    this.getRecipientsList()
  },
  methods: {
    async getRecipientsList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddwineCore.DealersActions.getDealerRecipients(
          this.$route.params.id,
        )

      loading.close()

      if (error) return

      this.recipients = value
    },
    async remove(data) {
      const isConfirm = confirm(
        `Вы действительно хотите удалить получателя: ${data.lastName} ${data.firstName} ${data.middleName}`,
      )

      if (isConfirm) {
        const loading = this.$loading({
          lock: true,
        })

        const result =
          await delivery.AddwineCore.DealersActions.deleteDealerRecipient(
            data.dealerAccountId,
            data.id,
          )

        loading.close()

        if (result.error) return

        this.getRecipientsList()

        this.showNotification('Получатель успешно удален', 'success')
      }
    },
  },
}
</script>
<style lang="scss" module>
.recipients {
  padding: 1rem;

  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 0.063rem solid $light-gray;
    margin-bottom: 2rem;
    gap: 0.5rem;
    text-decoration: none;
    color: $black-gray;
    &:hover {
      background: $white;
    }
    .plus {
      fill: $black-gray;
      width: 1rem;
      height: 1rem;
    }
  }

  .buttons {
    justify-content: flex-end;
  }
}
</style>
